<template>
   <div class="brandName">
      <div ref="search" class="search">
         <el-card class="box-card" shadow="never" :body-style="{padding:'10px'}">
            <el-form :inline="true" :model="searchForm" ref="searchForm" label-width="80px">
               <!-- :rules="searchRules" -->
               <search-open-btn :searchShow="searchShow" @searchOpen="searchOpen"></search-open-btn>
               <el-form-item label="制造商">
                  <el-select v-model="searchForm.goodsBiCustomerId" :size="$store.state.size" placeholder="制造商" clearable @change="getProductList">
                     <el-option v-for="e in manufacturerList" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="品牌名称">
                  <el-input v-model="searchForm.goodsBiName" :size="$store.state.size" placeholder="品牌名称" clearable @keyup.enter.native="searchSubmit(true,'searchForm')"></el-input>
               </el-form-item>
               <el-form-item label="产地">
                  <el-input v-model="searchForm.goodsBiPlace" :size="$store.state.size" placeholder="产地" clearable @keyup.enter.native="searchSubmit(true,'searchForm')"></el-input>
               </el-form-item>
               <el-form-item label="注册证号">
                  <el-input v-model="searchForm.goodsBiRegistrationnum" :size="$store.state.size" placeholder="注册证号" clearable @keyup.enter.native="searchSubmit(true,'searchForm')"></el-input>
               </el-form-item>
               <el-form-item label="生产企业许可证号" v-show="searchShow" label-width="132px">
                  <el-input v-model="searchForm.goodsBiLicensenumber" :size="$store.state.size" placeholder="生产企业许可证号" clearable @keyup.enter.native="searchSubmit(true,'searchForm')"></el-input>
               </el-form-item>
               <el-form-item label="启用状态" v-show="searchShow">
                  <el-select v-model="searchForm.goodsBiStatus" :size="$store.state.size" placeholder="启用状态" clearable>
                     <el-option label="启用" :value="0"></el-option>
                     <el-option label="停用" :value="1"></el-option>
                  </el-select>
               </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <el-button v-if="hasPermission('3-3-a')" type="primary" :size="$store.state.size" @click="searchSubmit(true,'searchForm')" icon="el-icon-search">查询
            </el-button>
            <el-button v-if="hasPermission('3-3-b')" type="default" plain :size="$store.state.size" @click="resetSubmit" icon="el-icon-delete">清空
            </el-button>
            <el-button v-if="hasPermission('3-3-c')" type="primary" :size="$store.state.size" @click="saveOpen" icon="el-icon-circle-plus-outline">新增
            </el-button>
         </el-card>
      </div>

      <el-table :data="tableData" :height="tableHeight" v-loading="tableLoading" border @sort-change="sortChange" :size="this.$common.tableLineHeight()" :header-cell-style="{background:'#f5f7fa',color:'#515a6e', fontWeight:'900'}" style="width: 100%; margin-top: 10px">
         <el-table-column prop="customerName" align="center" label="所属制造商">
         </el-table-column>
         <el-table-column prop="goodsBiName" align="center" label="品牌名称">
         </el-table-column>
         <el-table-column prop="goodsBiPlace" align="center" label="产地">
         </el-table-column>
         <el-table-column prop="goodsBiCode" align="center" width="100" label="编码" v-if="isShow">
         </el-table-column>
         <el-table-column prop="goodsBiRegistrationnum" align="center" label="注册证号">
         </el-table-column>
         <el-table-column prop="goodsBiLicensenumber" align="center" label="生产企业许可证号">
         </el-table-column>
         <el-table-column align="center" label="启用状态" width="150">
            <template slot-scope="scope" width="150">
               <el-switch v-model="scope.row.goodsBiStatus" :active-value="0" :inactive-value="1" active-text="启用" inactive-text="停用" @change="stopBtn(scope.row)">
               </el-switch>
            </template>
         </el-table-column>
         <el-table-column align="center" label="操作" width="260">
            <template slot-scope="scope">
               <el-button v-if="hasPermission('3-3-d')" type="text" size="small" @click="seeOpen(scope.row)" icon="el-icon-document">查看
               </el-button>
               <el-button v-if="hasPermission('3-3-e')" type="text" size="small" @click="editOpen(scope.row)" icon="el-icon-edit-outline">编辑
               </el-button>
               <el-button v-if="hasPermission('3-3-f')" type="text" size="small" @click="delOpen(scope.row)" icon="el-icon-delete">删除
               </el-button>
            </template>
         </el-table-column>
      </el-table>
      <pagination :page-size='currentPageSize' :current-page="currentPage" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </pagination>

      <!--    保存模态框-->
      <assembly-dialog :title="dialogTitle" :visible.sync="dialogVisibleSave" width="30%" destroy-on-close @before-close="handleClose">
         <el-form :model="saveForm" :rules="saveRules" ref="saveForm" label-width="80px">
            <el-form-item label="制造商" prop="goodsBiCustomerId">
               <el-select v-model="saveForm.goodsBiCustomerId" :size="$store.state.size" placeholder="制造商" clearable :disabled="dialogStatus" @change="getProductList">
                  <el-option v-for="e in manufacturerList" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId" @click.native="getBrandCode(e.sysCiId)"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="编码" prop="goodsBiCode" v-show="showCode&&saveForm.goodsBiCustomerId" v-if="isShow">
               <el-input class="width50" v-model="saveForm.goodsBiCode" :size="$store.state.size" readonly placeholder="品牌编码" :disabled="dialogStatus" clearable></el-input>
            </el-form-item>
            <el-form-item label="品牌名称" prop="goodsBiName">
               <el-input v-model="saveForm.goodsBiName" :size="$store.state.size" placeholder="品牌名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="产地" prop="goodsBiPlace">
               <el-input v-model="saveForm.goodsBiPlace" :size="$store.state.size" placeholder="产地" clearable></el-input>
            </el-form-item>
            <el-form-item label="注册证号" prop="goodsBiRegistrationnum">
               <el-input v-model="saveForm.goodsBiRegistrationnum" :size="$store.state.size" placeholder="注册证号" clearable></el-input>
            </el-form-item>
            <el-form-item label="许可证号" prop="goodsBiLicensenumber">
               <el-input v-model="saveForm.goodsBiLicensenumber" :size="$store.state.size" placeholder="生产企业许可证号" clearable></el-input>
            </el-form-item>
            <el-form-item label="启用状态" prop="goodsBiStatus">
               <el-select v-model="saveForm.goodsBiStatus" :size="$store.state.size" placeholder="启用状态" clearable>
                  <el-option label="启用" :value="0"></el-option>
                  <el-option label="停用" :value="1"></el-option>
               </el-select>
            </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="clearForm('saveForm')">清 空</el-button>
            <el-button :size="$store.state.size" type="primary" @click="saveSubmit('saveForm')">确 定</el-button>
         </span>
      </assembly-dialog>
      <!--    查看模态框-->
      <assembly-dialog title="查看" :visible.sync="dialogVisibleSee" width="30%" @before-close="seeClose">
         <el-descriptions class="margin-top" :column="1" :size="$store.state.size" border>
            {{ seeForm }}
            <el-descriptions-item>
               <template slot="label">
                  制造商
               </template>
               {{ seeForm.customerName }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  品牌名称
               </template>
               {{ seeForm.goodsBiName }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  产地
               </template>
               {{ seeForm.goodsBiPlace }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  编码
               </template>
               {{ seeForm.goodsBiCode }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  注册证号
               </template>
               {{ seeForm.goodsBiRegistrationnum }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  生产企业生产证号
               </template>
               {{ seeForm.goodsBiLicensenumber }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  启用状态
               </template>
               {{ seeForm.goodsBiStatus == '0' ? '启用' : '停用' }}
            </el-descriptions-item>
         </el-descriptions>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="seeClose">关 闭</el-button>
         </span>
      </assembly-dialog>
   </div>
</template>

<script>
import searchOpenBtn from "@/components/assembly-search/search-open-btn";
import pagination from "@/components/assembly-pagination/index";
import AssemblyDialog from "components/assembly-dialog";


export default {
   name: "brandName",
   components: {
      AssemblyDialog,
      searchOpenBtn,
      pagination,
      //AssemblyAutocomplete,
   },
   watch: {
      //监听头部展开收起变化
      '$store.state.isHeader'() {
         this.setTableHeight()
      },
      //监听快速导航展开收起变化
      '$store.state.fastNav'() {
         this.setTableHeight()
      },
      //监听搜索区域变化改变列表
      searchShow() {
         this.setTableHeight()
      },
      //监听制造商下拉框变化
      'searchForm.goodsIciCustomerId'() {
         if (this.searchForm.goodsBiCustomerId) {
            this.searchForm.goodsBiName = ''
            this.$refs.assemblyAutocomplete.clear()
            this.goodsPiNameChange()
         } else {
            this.goodsPiNameList = []
            //this.$refs.assemblyAutocomplete.setList(this.goodsPiNameList)
         }
      },
      //监听品名内容变化搜索对应品名列表
      // 'searchForm.productName'() {
      //   if (this.searchForm.productName || this.searchForm.goodsIciCustomerId){
      //     // this.searchForm.productName =''
      //     this.goodsPiNameChange()
      //   } else {
      //     this.goodsPiNameList = []
      //    // this.$refs.assemblyAutocomplete.setList(this.goodsPiNameList)
      //   }
      // }
   },
   data() {
      return {
         currentPageSize: 10,
         dialogTitle: '新增',
         //搜索区域展开收起开关
         searchShow: false,
         //搜索区域展开收起图标
         searchOpenIcon: 'el-icon-arrow-down',
         //加载
         tableLoading: false,
         //表格高度
         tableHeight: 0,
         //表格数据
         tableData: [],
         //当前页面
         currentPage: 1,
         //总页数
         total: 0,
         //是否是修改模态窗
         dialogStatus: false,
         //搜索表单
         searchForm: {
            page: '1',
            pageSize: this.$store.state.initPageSize,
            customerName: '',
            goodsBiCustomerId: '',
            goodsBiName: '',
            goodsBiPlace: '',
            goodsBiRegistrationnum: '',
            goodsBiLicensenumber: '',
            goodsBiStatus: ''
         },
         //保存模态框开关
         dialogVisibleSave: false,
         //隐藏模块
         isShow: false,
         //保存表单
         saveForm: {
            goodsBiId: '',
            goodsBiCustomerId: '',
            goodsBiCode: '',
            goodsBiName: '',
            goodsBiPlace: '',
            goodsBiRegistrationnum: '',
            goodsBiLicensenumber: '',
            goodsBiStatus: 0
         },
         //保存表单验证
         saveRules: {
            goodsBiCustomerId: [
               { required: true, message: '请选择所属制造商', trigger: 'change' }
            ],
            goodsBiName: [
               { required: true, message: '请输入品牌名称', trigger: 'change' }
            ],
            goodsBiStatus: [
               { required: true, message: '请选择启用状态', trigger: 'change' }
            ]
         },
         //查看模态框开关
         dialogVisibleSee: false,
         seeForm: {
            customerName: '',
            goodsBiCode: '',
            goodsBiName: '',
            goodsBiPlace: '',
            goodsBiRegistrationnum: '',
            goodsBiLicensenumber: '',
            goodsBiStatus: '',
            userid: ''
         },
         //制造商列表
         manufacturerList: [],
         //  商品名称列表
         ProductList: [],
         //搜索品名列表
         searchProductList: [],
         //品名搜索集合
         goodsPiNameList: [],
         //显示编码
         showCode: false,
         isSave: 1, //编辑查询传1，新增传0
         isAdd: false
      }
   },
   mounted() {
      //初始化计算表格区域高度
      this.setTableHeight();
      //  初始化制造商列表
      this.manufacturer();
      //  初始化表单
      this.searchSubmit(false, 'searchForm');
      // this.searchForm.productName = ''
   },
   methods: {
      //判断权限是否存在
      hasPermission(item) {
         return this.$common.hasPermission(item);
      },
      /****表格高度计算 start********************************************************************************/
      //计算表格区域高度
      setTableHeight() {
         this.$nextTick(() => {
            this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
            window.onresize = () => {
               return (() => {
                  this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
               })();
            };
         })
      },
      /****表格高度计算 end********************************************************************************/
      //制造商列表
      manufacturer() {
         let user = JSON.parse(sessionStorage.getItem('userInfo'));
         this.isSave = this.isAdd == true ? 0 : 1
         this.$server.getList({ sysCiType: 1, userid: user.sysUiId, isSave: this.isSave }).then((res) => {
            if (res.ok) {
               this.manufacturerList = res.data
            }
         }).catch(e => {
            console.log(e)
         })
      },
      //获取制造商列表
      getProductList() {
         this.isSave = this.isAdd == true ? 0 : 1
         this.$server.getGoodsPiNameList({
            goodsPiCustomerId: this.saveForm.goodsIciCustomerId,
            goodsPiName: this.saveForm.goodsPiName,
            isLens: 1,
            isSave: this.isSave
         }).then((res) => {
            if (res.ok) {
               // console.log('品名列表请求数据',JSON.stringify(res.data))
               this.ProductList = res.data
            }
         }).catch(e => {
            console.log(e)
         })
      },
      /****搜素区域 start********************************************************************************/
      //搜索展开收起事件
      searchOpen(searchShow) {
         this.searchShow = !this.searchShow
         this.searchOpenIcon = this.searchShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
      },
      //搜索表单提交
      searchSubmit(flag, searchForm) {
         if (flag) {
            this.currentPage = 1
            this.searchForm.pageSize = this.currentPageSize
            this.searchForm.page = 1
         }
         let user = JSON.parse(sessionStorage.getItem('userInfo'));
         this.searchForm.userid = user.sysUiId;
         this.$refs[searchForm].validate((valid) => {
            if (valid) {
               this.tableLoading = true
               console.log('搜索表单提交', JSON.stringify(this.searchForm))
               this.$server.getBrandByPage(this.searchForm).then((res) => {
                  this.tableLoading = false
                  if (res.ok) {
                     this.tableData = res.data.records
                     this.total = res.data.total
                  }
               }).catch(e => {
                  this.tableLoading = false
                  console.log(e)
               })
            } else {
               this.tableLoading = false
               return false;
            }
         });

      },
      //搜索表单重置
      resetSubmit() {
         this.currentPage = 1
         this.$common.clearFormValue(this.searchForm)
         // this.$refs.assemblyAutocomplete.clear()
         this.searchForm.page = 1;
         this.searchForm.pageSize = this.currentPageSize
         this.searchSubmit(false, 'searchForm');
      },
      /****搜素区域 end********************************************************************************/
      /****保存区域 start********************************************************************************/
      //获取品牌编码
      getBrandCode(row) {
         console.log('获取品牌编码', JSON.stringify(row))
         this.showCode = true
         this.$server.getBrandCode({ 'goodsBiCustomerId': row }).then(res => {
            if (res.ok) {
               this.saveForm.goodsBiCode = res.msg
            }
         }).catch(e => {
            console.log(e)
         })
      },
      //保存表单提交
      saveOpen() {
         this.dialogTitle = '新增'
         this.dialogVisibleSave = true;
         this.dialogStatus = false;
         this.saveForm.goodsBiStatus = 0
      },
      //模态框关闭前回调
      handleClose() {
         this.dialogVisibleSave = false;
         this.saveForm.goodsBiCustomerId = "";
         this.clearForm('saveForm')
      },
      _resetPage() {
         this.currentPage = 1,
            this.searchForm.page = 1
      },
      //保存提交
      saveSubmit(formName) {
         this.$refs[formName].validate((valid) => {
            if (valid) {
               this.$server.brandSaveOrUpdate(this.saveForm).then(res => {
                  if (res.ok) {
                     this._resetPage()
                     this.$message.success(res.msg)
                     this.showCode = false
                     this.dialogVisibleSave = false
                     /*this.clearForm('saveForm')*/
                     this.$common.clearFormValue(this.saveForm)
                     this.searchSubmit(true, 'searchForm')
                  } else {
                     this.$message.error(res.msg)

                  }
               }).catch(e => {
                  console.log(e)
               })
            } else {
               console.log('error submit!!');
               return false;
            }
         });
      },
      //停启用按钮
      stopBtn(formName) {
         this.$server.brandSaveOrUpdate(formName).then(res => {
            if (res.ok) {
               this.$message.success(res.msg)
               this.searchSubmit(false, 'searchForm');
            } else {
               this.$message.error(res.msg)
            }
            this.dialogVisibleSave = false
            /*this.$common.clearFormValue(this.saveForm)*/
         }).catch(e => {
            console.log(e)
         })
      },
      //清空表单
      clearForm(formName) {
         // console.log(this[formName])
         // return;
         let goodsBiCustomerId = "", goodsBiId = null, goodsBiVersion = "";
         if (formName == "saveForm") {
            goodsBiCustomerId = this.saveForm.goodsBiCustomerId;
            goodsBiId = this.saveForm.goodsBiId;
            goodsBiVersion = this.saveForm.goodsBiVersion;
         }
         /*      this.searchForm.page = 1;
               this.searchForm.pageSize = this.$store.state.initPageSize ? this.$store.state.initPageSize : 10;*/
         this.showCode = false;
         this.$refs[formName].clearValidate();
         this.$common.clearFormValue(this[formName]);
         if (formName == "saveForm") {
            this.saveForm.goodsBiCustomerId = goodsBiCustomerId;
            this.saveForm.goodsBiId = goodsBiId;
            this.saveForm.goodsBiVersion = goodsBiVersion;
         }
         this.$nextTick(() => {
            //
         })

      },
      //编辑模态框开启
      editOpen(row) {
         this.dialogTitle = '编辑'
         this.saveForm = JSON.parse(JSON.stringify(row))
         this.dialogVisibleSave = true
         this.showCode = true
         this.dialogStatus = true
      },
      /****保存区域 end********************************************************************************/
      /****查看区域 start********************************************************************************/
      seeOpen(row) {
         this.seeForm = row
         console.log('查看区域==', JSON.stringify(row))

         this.dialogVisibleSee = true
      },
      seeClose() {
         this.dialogVisibleSee = false
      },
      /****查看区域 end********************************************************************************/
      /****删除区域 start********************************************************************************/
      delOpen(row) {
         this.$confirm('确认删除该条数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            console.log('删除', row)
            this.$server.brandDelete({ id: row.goodsBiId }).then((res) => {
               console.log("id", 11111);
               if (res.ok) {
                  console.log(this.searchForm, '保存提交', JSON.stringify(res.data))
                  this.$message({
                     type: 'success',
                     message: res.msg
                  });
                  this.searchSubmit(false, 'searchForm');
               } else {
                  this.$message.error(res.msg)
               }
            }).catch(e => {
               console.log(e)
            })
         }).catch(() => {
            this.$message({
               type: 'info',
               message: '已取消删除'
            });
         });
      },
      /****删除区域 end********************************************************************************/
      handleSizeChange(val) {
         this.currentPage = 1
         this.currentPageSize = val
         this.searchForm.page = 1
         this.searchForm.pageSize = val
         this.searchSubmit(false, 'searchForm');
      },
      handleCurrentChange(val) {
         this.searchForm.page = val
         this.currentPage = val
         this.searchSubmit(false, 'searchForm');
      },
      //品名搜索
      // selectBlur(e) {
      //   console.log('品名搜索',JSON.stringify(e))
      //     this.$refs.fuzzySearch.$refs.input.blur = () => {
      //       this.searchForm.productName = e.target.value
      //     };
      //   // this.searchForm.productName = e.target.value
      // },
      //排序
      sortChange(row) {
         console.log('排序排序排序', JSON.stringify(row))
         if (row.prop == 'customerName') {
            this.searchForm.orderByColumn = 'goodsIciId'
         } else if (row.prop == 'productName') {
            this.searchForm.orderByColumn = 'goodsIciId'
         } else {
            this.searchForm.orderByColumn = row.prop
         }
         if (row.order == 'ascending') {
            this.searchForm.order = 'asc'
         } else if (row.order == 'descending') {
            this.searchForm.order = 'desc'

         } else {
            this.searchForm.order = ''
         }
         // this.searchForm.page = 1;
         this.searchSubmit(true, 'searchForm');
      },
      onSubmit() {
         console.log('submit!');
      },
      //商品类型修改
      productType(row) {
         if (row == 1) {
            this.saveForm.goodsIciCyl = ''
            this.saveForm.goodsIciSph = ''
         }
      },
      //根据品名查询集合
      // goodsPiNameChange() {
      //   this.$server.getGoodsPiNameList({
      //     goodsPiCustomerId: this.searchForm.goodsIciCustomerId,
      //     goodsPiName: this.searchForm.productName
      //   }).then(res => {
      //     if (res.ok) {
      //       this.goodsPiNameList = []
      //       for (const re of res.data) {
      //         this.goodsPiNameList.push({value:re.goodsPiName})
      //       }
      //      // this.$refs.assemblyAutocomplete.setList(this.goodsPiNameList)
      //     }
      //
      //   }).catch(e => {
      //     console.log(e)
      //   })
      // }
   },
}
</script>

<style lang="scss">
.brandName {
   .search {
      //搜索展开收起按钮位置设置
      .searchOpenBtn {
         float: right;
      }

      //表单每行底部外边距设置
      .el-form-item {
         margin-bottom: 10px;
      }

      .el-select {
         width: 200px;
      }

      .el-input {
         width: 200px;
      }

      //分割线外边距设置
      .el-divider--horizontal {
         margin: 0 0 10px 0;
      }
   }

   .el-dialog {
      .el-select {
         width: 100%;
      }
   }
}
</style>

<style scoped>
::v-deep .el-dialog {
   display: flex;
   flex-direction: column;
   margin: 0 !important;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   max-height: calc(100% - 30px);
   max-width: calc(100% - 30px);
}
</style>
